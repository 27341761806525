import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgTitleAgb from "../images/page_title_agb.png"

const Agbs = props => {
  return (
    <Layout>
      <Seo title="datenschutz" />
      <section className="agbs container">
        <img
          className="page-title"
          src={imgTitleAgb}
          alt="kaffeerösterei in peine"
        />

        <p>
          auf grundlage dieser allgemeinen geschäftsbedingungen (agbs) kommt
          <br />
          zwischen dem kunden/der kundin und dem anbieter:
        </p>

        <p>
          das kleine röstwerk
          <br />
          vertreten durch thorsten tessmer
          <br />
          adresse: heinrich-hertz-straße 7 · 31228 peine
          <br />
          telefon: 05171 5083 - 70
          <br />
          telefax: 05171 5083 - 69
          <br />
          e-mail: info@daskleineroestwerk.de
        </p>

        <p>
          umsatzsteuer-identifikationsnummer: 264350972
          <br />
          der vertrag zustande.
        </p>
        <p>
          <strong>vertragsgegenstand</strong>
          <br />
          durch diesen vertrag wird der verkauf von neuen waren über den
          online-shop des anbieters geregelt. wegen der details des jeweiligen
          angebotes wird auf die produktbeschreibung der angebotsseite
          verwiesen.
        </p>

        <p>
          <strong>vertragsschluss</strong>
          <br />
          der vertrag kommt ausschließlich im elektronischen geschäftsverkehr
          über das shop-system zustande. dabei stellen die dargestellten
          angebote eine unverbindliche aufforderung zur abgabe eines angebots
          durch die kundenbestellung dar, das der anbieter dann annehmen kann.
          der bestellvorgang zum vertragsschluss umfasst im shop-system folgende
          schritte:
        </p>

        <ul>
          <li>
            <p>
              auswahl des angebots in der gewünschten spezifikation (gewicht,
              mahlgrad, anzahl)
            </p>
          </li>
          <li>
            <p>einlegen des angebots in den warenkorb</p>
          </li>
          <li>
            <p>betätigen des buttons ‚bestellen‘</p>
          </li>
          <li>
            <p>eingabe der rechnungs- und lieferadresse</p>
          </li>
          <li>
            <p>auswahl der bezahlmethode</p>
          </li>
          <li>
            <p>überprüfung und bearbeitung der bestellung und aller eingaben</p>
          </li>
          <li>
            <p>betätigen des buttons ‚kostenpflichtig bestellen‘</p>
          </li>
          <li>
            <p>Bestätigungsmail, dass Bestellung eingegangen ist</p>
          </li>
        </ul>
        <p>bestätigungsmail, dass bestellung eingegangen ist</p>

        <p>
          <strong>vertragsdauer</strong>
          <br />
          der vertrag hat vorbehaltlich einer kündigung eine laufzeit von 1
          lieferungen.
        </p>

        <p>
          <strong>preise, versandkosten, rücksendekosten</strong>
          <br />
          alle preise sind endpreise und enthalten die gesetzliche umsatzsteuer.
          neben den endpreisen fallen je nach versandart weitere kosten an, die
          vor versendung der bestellung angezeigt werden. besteht ein
          widerrufsrecht und wird von diesem gebraucht gemacht, trägt der kunde
          die kosten der rücksendung.
        </p>

        <p>
          <strong>zahlungsbedingungen</strong>
          <br />
          der kunde hat ausschließlich folgende möglichkeiten zur zahlung:
          vorabüberweisung, rechnung bei lieferung, zahlungsdienstleister
          (paypal). weitere zahlungsarten werden nicht angeboten und werden
          zurückgewiesen.
        </p>

        <p>
          der rechnungsbetrag ist nach zugang der rechnung, die alle angaben für
          die überweisung enthält und mit e-mail verschickt wird, auf das dort
          angegebene konto vorab zu überweisen. der rechnungsbetrag ist nach
          zugang der rechnung, die alle angaben für die überweisung enthält und
          mit der lieferung verschickt wird, auf das dort angegebene konto vorab
          zu überweisen. bei verwendung eines treuhandservice/
          zahlungsdienstleisters ermöglicht es dieser dem anbieter und kunden,
          die zahlung untereinander abzuwickeln. dabei leitet der
          treuhandservice/ zahlungsdienstleister die zahlung des kunden an den
          anbieter weiter. weitere informationen erhalten sie auf der
          internetseite des jeweiligen treuhandservices/ zahlungsdienstleisters.
          der kunde ist verpflichtet innerhalb von 14 tagen nach erhalt der
          rechnung den ausgewiesenen betrag auf das auf der rechnung angegebene
          konto einzuzahlen oder zu überweisen. die zahlung ist ab
          rechnungsdatum ohne abzug fällig. der kunde kommt erst nach mahnung in
          verzug.
        </p>

        <p>
          <strong>lieferbedingungen</strong>
          <br />
          die ware wird umgehend nach bestätigtem zahlungseingang versandt. der
          versand erfolgt durchschnittlich spätestens nach 4 tagen. der
          unternehmer verpflichtet sich zur lieferung am 6. tag nach
          bestelleingang. die regellieferzeit beträgt 4 tage, wenn in der
          artikelbeschreibung nichts anderes angegeben ist. der anbieter
          versendet die bestellung aus eigenem lager, sobald die gesamte
          bestellung dort vorrätig ist. der kunde wird über verzögerungen
          umgehend informiert. hat der anbieter ein dauerhaftes lieferhindernis,
          insbesondere höhere gewalt oder nichtbelieferung durch eigenen
          lieferanten, obwohl rechtzeitig ein entsprechendes deckungsgeschäft
          getätigt wurde, nicht zu vertreten, so hat der anbieter das recht,
          insoweit von einem vertrag mit dem kunden zurückzutreten. der kunde
          wird darüber unverzüglich informiert und empfangene leistungen,
          insbesondere zahlungen, zurückerstattet.
        </p>

        <p>
          <strong>vertragsgestaltung</strong>
          <br />
          der kunde hat keine möglichkeit selbst direkt auf den gespeicherten
          vertragstext zuzugreifen.
        </p>

        <p>
          <strong>widerrufsbelehrung</strong>
        </p>

        <p>
          <strong>widerrufsrecht</strong>
          <br />
          sie haben das recht, binnen vierzehn tagen ohne angabe von gründen
          diesen vertrag zu widerrufen.
          <br />
          die widerrufsfrist beträgt vierzehn tage ab dem tag,
        </p>

        <p>
          im falle eines kaufvertrags: an dem sie oder ein von ihnen benannter
          dritter, der nicht der beförderer ist, die letzte ware in besitz
          genommen haben bzw. hat.
        </p>

        <p>
          im falle einer vertrags über mehrere waren, die der verbraucher im
          rahmen einer einheitlichen bestellung bestellt hat und die getrennt
          geliefert werden: an dem sie oder ein von ihnen benannter dritter, der
          nicht beförderer ist, die letzte ware in besitz genommen haben bzw.
          hat.
        </p>

        <p>
          im falle eines vertrags über die lieferung einer ware in mehreren
          teilsendungen oder stücken: an dem sie oder ein von ihnen benannter
          dritter, der nicht beförderer ist, die letzte teilsendung oder das
          letzte stück in besitz genommen haben bzw. hat.
        </p>

        <p>
          im falle eines vertrages zur regelmäßigen lieferung von waren über
          einen festgelegten zeitraum hinweg: an dem sie oder ein von ihnen
          benannter dritter, der nicht beförderer ist, die erste ware in besitz
          genommen haben bzw. hat.
        </p>

        <p>
          beim zusammentreffen mehrerer alternativen ist der jeweils letzte
          zeitpunkt maßgeblich.
        </p>

        <p>
          um ihr widerrufsrecht auszuüben, müssen sie uns, das kleine röstwerk,
          thorsten tessmer, heinrich-hertz-straße 7, 31228 peine, telefon: 05171
          5083-70, telefax: 05171 5083-69, e-mail:{" "}
          <a href="mailto:info@daskleineröstwerk.de">
            info@daskleineroestwerk.de
          </a>
          ) mittels einer eindeutigen erklärung (z.b. telefax, oder e-mail) über
          ihren entschluss, diesen vertrag zu widerrufen, informieren. sie
          können dafür das beigefügte muster-widerrufsformular verwenden, das
          jedoch nicht vorgeschrieben ist.
        </p>

        <p>
          zur wahrung der widerrufsfrist reicht es aus, dass sie die mitteilung
          über die ausübung des widerrufsrechts vor ablauf der widerrufsfrist
          absenden
        </p>

        <p>
          <strong>folgen des widerrufs</strong>
          <br />
          wenn sie diesen vertag widerrufen, haben wir ihnen alle zahlungen, die
          wir von ihnen erhalten haben, einschließlich der lieferkosten (mit
          ausnahmen der zusätzlichen kosten, die sich daraus ergeben, dass sie
          einer andere art der lieferung als die von uns angebotene, günstige
          standardlieferung gewählt haben), unverzüglich und spätestens binnen
          vierzehn tagen ab dem tag zurückzuzahlen, an dem die mitteilung über
          ihren widerruf dieses vertrags bei uns eingegangen ist. für diese
          rückzahlung verwenden wir dasselbe zahlungsmittel, das sie bei der
          ursprünglichen transaktion eingesetzt haben, es sei denn, mit ihnen
          wurde ausdrücklich etwas anderes vereinbart; in keinem fall werden
          ihnen wegen dieser rückzahlung entgelte berechnet. wir können die
          rückzahlung verweigern, bis wir die waren wieder zurückerhalten haben
          oder bis sie den nachweis erbracht haben, dass sie die waren
          zurückgesandt haben, je nachdem, welches der frühere zeitpunkt ist.
        </p>

        <p>
          sie haben die waren unverzüglich und in jedem fall spätestens binnen
          vierzehn tagen ab dem tag, an dem sie uns über den widerruf dieses
          vertrags unterrichten, an (das kleine röstwerk, thorsten tessmer,
          heinrich-hertz-straße 7, 31228 peine, telefon: 05171 5083-70, telefax:
          05171 5083-69, e-mail:
          <a href="mailto:info@daskleineröstwerk.de">
            info@daskleineroestwerk.de
          </a>
          ) uns zurückzusenden oder zu übergeben. die frist ist gewahrt, wenn
          sie die waren vor ablauf der frist von vierzehn tagen absenden.
        </p>

        <p>sie tragen die unmittelbaren kosten der rücksendung der waren.</p>

        <p>
          sie müssen für einen etwaigen wertverlust der waren nur aufkommen,
          wenn dieser wertverlust auf einen zur prüfung der beschaffenheit,
          eigenschaften und funktionsweise der waren nicht notwendigen umgang
          mit ihnen zurückzuführen ist.
        </p>

        <p>
          <strong>ende der widerrufsbelehrung</strong>
          <br />
          sie erreichen unseren kundendienst zu folgenden zeiten unter: (das
          kleine röstwerk, thorsten tessmer, heinrich-hertz-straße 7, 31228
          peine, telefon: 05171 5083-70, telefax: 05171 5083-69, e-mail:
          <a href="mailto:info@daskleineröstwerk.de">
            info@daskleineroestwerk.de
          </a>
          ) öffnungszeiten: mo.-fr. 10.00-16.00 uhr
        </p>

        <p>
          <strong>sprache, gerichtsstand und anzuwendendes recht</strong>
          <br />
          der vertrag wird in deutsch abgefasst. die weitere durchführung der
          vertragsbeziehung erfolgt in deutsch. es findet ausschließlich das
          recht der bundesrepublik deutschland anwendung. für verbraucher gilt
          dies nur insoweit, als dadurch keine gesetzlichen bestimmungen des
          staates eingeschränkt werden, in dem der kunde seinen wohnsitz oder
          gewöhnlichen aufenthalt hat. gerichtsstand ist bei streitigkeiten
          kunden/kundinnen, die keine verbraucher, juristische personen des
          öffentlichen rechts oder öffentlich-rechtliches sondervermögen sind,
          sitz des anbieters.
        </p>

        <p>
          <strong>datenschutz</strong>
          <br />
          im zusammenhang mit der anbahnung, abschluss, abwicklung und
          rückabwicklung eines kaufvertrages auf grundlage dieser agb werden vom
          anbieter daten erhoben, gespeichert und verarbeitet. dies geschieht im
          rahmen der gesetzlichen bestimmungen. der anbieter gibt keine
          personenbezogenen daten des kunden/der kundin an dritte weiter, es sei
          denn, dass er/sie hierzu gesetzlich verpflichtet wäre oder der
          kunde/die kundin vorher ausdrücklich eingewilligt hat.
          <br />
          wird ein dritter für dienstleistungen im zusammenhang mit der
          abwicklung von verarbeitungsprozessen eingesetzt, so werden die
          bestimmungen des bundesdatenschutzgesetzes eingehalten. die vom kunden
          im wege der bestellung mitgeteilten daten werden ausschließlich zur
          kontaktaufnahme innerhalb des rahmens der vertragsabwicklung und nur
          zu dem zweck verarbeitet, zu dem der kunde/die kundin die daten zur
          verfügung gestellt hat. die daten werden nur soweit notwendig an das
          versandunternehmen, das die lieferung der ware auftragsgemäß
          übernimmt, weitergegeben. die zahlungsdaten werden an das mit der
          zahlung beauftragte kreditinstitut weitergegeben. soweit den anbieter
          aufbewahrungsfristen handels- oder steuerrechtlicher natur treffen,
          kann die speicherung einiger daten bis zu zehn jahre dauern. während
          des besuchs im internet-shop des anbieters werden anonymisierte daten,
          die keine rückschlüssen auf personenbezogene daten zulassen und auch
          nicht beabsichtigen, insbesondere ip-adresse, datum, uhrzeit,
          browsertyp, betriebssystem und besuchte seiten, protokolliert. auf
          wunsch des kunden/der kundin werden im rahmen der gesetzlichen
          bestimmungen die personenbezogenen daten gelöscht, korrigiert oder
          gesperrt. eine unentgeltliche auskunft über alle personenbezogenen
          daten des kunden/der kundin ist möglich. für fragen und anträge auf
          löschung, korrektur oder sperrung personenbezogener daten sowie
          erhebung, verarbeitung und nutzung kann sich der kunde an folgende
          adresse wenden: (das kleine röstwerk, thorsten tessmer,
          heinrich-hertz-straße 7, 31228 peine, telefon: 05171 5083-70, telefax:
          05171 5083-69, e-mail:
          <a href="mailto:info@daskleineröstwerk.de">
            info@daskleineroestwerk.de
          </a>
          ).
        </p>
      </section>
    </Layout>
  )
}

export default Agbs
